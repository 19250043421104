
import { useDispatch, useSelector } from "react-redux";

import { carritoStartDeleteCompra } from "../../../actions/carrito";
import { carritoStartDeleteCliente } from "../../../actions/carritoCliente";
import { ViewPreciosPorRol } from "../../components";


const baseUrl = process.env.REACT_APP_API_URL;



export const TablaDeCarritoEnModal = () => {


        
    const dispatch = useDispatch();
    const { usuario } = useSelector( state => state.admin );
    const { role } = useSelector( state => state.authCliente );
    const { carritoCompra } = useSelector( state => state.carrito );



    const handleDelete = ( id ) => {
        dispatch( carritoStartDeleteCompra(id) );
    }


    const handleDeleteCliente = ( id ) => {
        dispatch( carritoStartDeleteCliente(id) );
    }


    return (

        <>
        {carritoCompra &&
            <table className="table text-center">
                <tbody>
                {
                carritoCompra.map( c => (
                    <tr key={c.id} className='comprar-carrito'>
                        <td >
                            <div className='img-comprar'>
                                <img src={ `${ baseUrl }/uploads/productos/${ c.producto.img }` } style={{width:100}} />
                            </div>
                        </td>

                        <td className='cantidad-carrito'>
                            <h5 className="nombre-prod" style={{fontSize:'14px', color:'white'}}>{ c.producto.nombre }</h5>
                            <input type="number" readOnly
                                value={ c.cantidad }
                                name="cantidad"
                                // onChange={ handleInputChange }
                            />
                        </td>

                        <td className="precios">

                            <ViewPreciosPorRol formCarrito={c} mostrar={false} />

                        </td>

                        <td>
                        {
                        ( ( usuario ) && role !== 'CLIENTE' )

                        ?   <div className="comprar-eliminar">
                                <i className="fa-solid fa-trash-can"
                                    onClick={ () => handleDelete( c.id ) }
                                ></i>
                            </div>
                        :   <div className="comprar-eliminar">
                                <i className="fa-solid fa-trash-can"
                                    onClick={ () => handleDeleteCliente( c.id ) }
                                ></i>
                            </div>
                        } 
                        </td>
                    </tr>
                ))
                }
                </tbody>

            </table> 
        }
        </>
    )
}
