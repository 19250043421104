import { Grid } from '@mui/material';
import { useEffect } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { carritoListaCompraStart } from '../../actions/carrito';
import { closeModalCarrito } from '../../actions/modalCarrito';
import { TablaDeCarritoEnModal } from './components';
import { VerPrecioTotalCarrito } from '../components';


const customStyles = {
    content: {
    top: '50%',
    left: 'auto',
    right: '-50%',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    },
};


Modal.setAppElement('#root');



export const ModalCarrito = () => {


    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { modalOpen } = useSelector( state => state.modal );
    const { carritoCompra } = useSelector( state => state.carrito );



    const closeModal = () => {
        dispatch( closeModalCarrito() );
    }

    useEffect(() => {
        dispatch( carritoListaCompraStart() );
    }, [ dispatch ]);



    const handlerComprar = () => {
        navigate('/super/comprar-ahora');
        dispatch( closeModalCarrito() );
    }



return (
    
    <Modal
        isOpen={ modalOpen }
        onRequestClose={ closeModal }
        style={customStyles}
        closeTimeoutMS={ 200 }
        className="modal"
        overlayClassName="modal-fondo"
    >

        <h3 className='title-carrito'>Productos del Carrito</h3>


        <TablaDeCarritoEnModal />

        
        <div className='fin-carrito'>


            <Grid sx={{ py:2, pl:1 }}>
                <VerPrecioTotalCarrito />
            </Grid>


            <Grid sx={{ flexDirection:'column', alignItems:'center'}}>
                {
                ( carritoCompra.length > 0 ) &&
                <button className='btn' 
                        onClick={ handlerComprar }
                >
                    Verificar
                </button>  
                }

                <button className='btn' 
                    onClick={ closeModal }
                >
                    Salir
                </button>  
            </Grid>

        </div>

    </Modal >

)

}
